import React from "react";
import ScheduleBtn from "../shared/appointment-btn";

const CallToAction = ({ CTAImage, message }) => {
    const backgroundImageStyle = {
        backgroundImage: `url(${CTAImage})`,
    };
    return (
        <div style={backgroundImageStyle} className="w-full h-[366px] bg-center bg-cover">
            <div className="w-full h-full bg-[#000000] bg-opacity-[50%] flex justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                    <h2 className="text-[32px] leading-[56px] font-semibold text-[#FFFFFF]">{message ? message : `Work With Us Today`}</h2>
                    <ScheduleBtn />
                </div>
            </div>
        </div>
    )
}

export default CallToAction;