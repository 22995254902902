import React from "react";
import { EmailIcon, LocationPin, PhoneIcon } from "../../../images";

const TopNav = () => {
    return(
        <nav className="w-full min-[823px]:h-[63px] h-[123px] bg-[#4B7670] min-[823px]:px-[90px] px-4 py-6 flex min-[823px]:justify-end">
            <div className="flex min-[823px]:flex-row flex-col min-[823px]:gap-8 gap-4 min-[823px]:text-[14px] text-[12px] min-[823px]:leading-[20px] leading-[15px] text-[#ffffff] justify-center">

                <div className="flex gap-2 items-center">
                    <img src={PhoneIcon} alt="phone" />
                    <p>+234 9020664276</p>
                </div>

                <div className="flex gap-2 items-center">
                    <img src={EmailIcon} alt="email" />
                    <p>info@myrtlelab.com</p>
                </div>

                <div className="flex gap-2 items-center">
                    <img src={LocationPin} alt="location" />
                    <p>No. 19, Dansuleiman Street, Utako, Abuja</p>
                </div>
            </div>
        </nav>
    )
}

export default TopNav;