import { otherService1, otherService2, otherService3, otherService4, otherService5 } from "../images";

export const OTHER_SERVICES = [
    {
        id: 1,
        image: otherService1,
        title: "Environmental Studies",
        description: "We offer comprehensive assessments of ecosystems, habitats, and environmental impacts. Through rigorous analysis and expert insights, we provide valuable information to support conservation efforts, land development projects, and regulatory compliance."
    },
    {
        id: 2,
        image: otherService2,
        title: "Remediation Services",
        description: "This service offer custom solutions for addressing environmental contamination and restoring ecosystem health. With expertise in cleanup technologies and regulatory compliance, we provide efficient remediation strategies to mitigate environmental risks and safeguard public health."
    },
    {
        id: 3,
        image: otherService3,
        title: "Laboratory Training",
        description: "We provide comprehensive instruction on environmental testing methodologies and laboratory best practices. With hands-on sessions and expert guidance, we empower professionals to enhance their skills and ensure accurate and reliable results in their laboratory work."
    },
    {
        id: 4,
        image: otherService4,
        title: "Report Production",
        description: "We deliver professional and comprehensive reports and documentation of analytical results. With meticulous attention to detail and adherence to industry standards, we ensure that your reports are accurate, clear, and actionable."
    },
    {
        id: 5,
        image: otherService5,
        title: "Laboratory Equipment Supply",
        description: "We supply a wide range of high-quality instruments and tools custom to meet your laboratory needs. From analytical instruments to consumables, we provide reliable products and expert guidance to support your environmental testing operations."
    },
]