import { Cost, Reliable, Seasoned } from "../images";

export const BENEFITS = [
    {
        id: "b-1",
        image: Reliable,
        title: "Reliable Data",
        description: "We provide precise analytical results and trusted insights on samples tested at our accredited laboratories or on-site. Count on us for dependable data to support your environmental and quality assurance needs."
    },
    {
        id: "b-2",
        image: Seasoned,
        title: "Seasoned Experts",
        description: "Our team of dedicated and experienced professionals delivers accurate analytical results for your samples, ensuring low error rates and meeting your environmental and quality standards."
    },
    {
        id: "b-3",
        image: Cost,
        title: "Cost Effective",
        description: "Our approach ensures high efficiency and affordability, delivering exceptional value without compromising quality. Our services are not only budget-friendly but also uphold the highest standards of quality and performance."
    },
]