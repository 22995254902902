import React, { useState, useEffect } from "react";
import { ArrowRight, Welcome, CloseIcon } from "../../images";
import Cookies from 'js-cookie'; // Import the cookies library

const IntroModal = ({setShowModal}) => {


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="sm:w-[55%] w-[95%] px-[22px] bg-white rounded-lg shadow-lg relative">
                <div className="flex justify-end items-center h-[56px]">
                    <img
                        src={CloseIcon}
                        alt="close"
                        className="cursor-pointer"
                        onClick={() => setShowModal(false)}
                    />
                </div>

                <div className="flex items-center gap-[43px] sm:p-6 p-2">
                    <img src={Welcome} alt="welcome" className="sm:block hidden" />

                    <div>
                        <h5 className="text-4 leading-6 font-semibold text-[#26262A]">
                            “Our precise testing and trusted insights help you maintain the highest standards for all of your environmental laboratory needs.
                            Schedule an appointment with us now for reliable results delivered timely.”
                        </h5>

                        <p className="text-[14px] leading-[20px] text-[#5E6068] mt-4">Dorathy Williams, CEO Myrtle Laboratory</p>

                        <button className="w-[170px] h-[48px] bg-[#275A53] font-semibold text-[#FFFFFF] flex items-center justify-center gap-2 mt-10">
                            Book Now
                            <img src={ArrowRight} alt="arrow right" className="w-4 h-4" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroModal;
