import React from "react";
import PageLayout from "../components/layout";
import Home from "../components/home";

const HomePage = () => {
    return (
        <PageLayout>
            <Home />
        </PageLayout>
    )
}

export default HomePage;