import React from "react";
import { WhatsappLogo } from "../../../images";

const WhatsappIcon = () => {
    return (
        <div className="fixed bottom-[10%] transform translate-y-1/2 z-50 md:bottom-8 right-3 md:translate-y-0 cursor-pointer">
            <img
                src={WhatsappLogo}
                alt="WhatsApp"
                className="h-[50px] w-[50px] md:h-[59px] md:w-[59px]"
            />
        </div>
    )
}

export default WhatsappIcon;