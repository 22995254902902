import React, { useEffect, useState } from "react";
import Nav from "./nav";
import Footer from "./footer";
import Accreditation from "./accreditation";
import WhatsappIcon from "./whatsapp";
import IntroModal from "../modals/intro";
import Cookies from 'js-cookie';

const PageLayout = ({children}) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {   
        const hasVisited = Cookies.get('hasVisited'); 
        if (!hasVisited) {
            setShowModal(true);
            Cookies.set('hasVisited', 'true', { expires: 7 }); 
        }
    }, []);
    return (
        <section className="relative">
            <Nav />

            <div className="w-full h-full">
                {children}
            </div>
            <Accreditation />
            <Footer />

            <WhatsappIcon />
            {showModal && <IntroModal setShowModal={setShowModal}/>}
        </section>
    )
}

export default PageLayout;