import React from "react";
import Jumbo from "./jumbo";
import Services from "./services";
import Benefits from "./benefits";
import Testimonials from "./clients";
import CaseStudies from "./case-studies";
import Blog from "./blog";
import CallToAction from "./cta";
import { HomepageCTA } from "../../images";

const Home = () => {
    return (
        <div>
            <Jumbo />
            <Services />
            <Benefits />
            <Testimonials />
            <CaseStudies />
            <Blog /> 
            {/* 
            */}
            <CallToAction CTAImage={HomepageCTA}/>
        </div>
    )
}

export default Home;