import React from "react";
import PageLayout from "../components/layout";
import Services from "../components/services";

const ServicesPage = () => {
    return (
        <PageLayout>
            <Services />
        </PageLayout>
    )
}

export default ServicesPage;