import React from "react";
import { ArrowRight, GreyArrow } from "../../images";
import { TESTIMONIALS } from "../../data/testimonials";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styles from "./carousel.module.css";


function NextArrow(props) {
    const { onClick, currentSlide, slideCount } = props;
    const slidesToShow = 2.3;

    const isDisabled = currentSlide == slideCount - slidesToShow;
    const arrowColorClass = isDisabled ? styles.disabledArrow : styles.enabledArrow;

    return (
        <>
            <div
                onClick={onClick}
                className={`${styles["circle_arrow_btn"]} ${styles["right_btn"]} ${arrowColorClass}`}
            >
                <img src={ArrowRight} />
            </div>
        </>
    );
}

function PrevArrow(props) {
    const { onClick, currentSlide } = props;

    const isDisabled = currentSlide === 0;
    const arrowColorClass = isDisabled ? styles.disabledArrow : styles.enabledArrow;

    return (
        <>
            <div
                onClick={onClick}
                className={`${styles["circle_arrow_btn"]} ${styles["left_btn"]} ${arrowColorClass}`}
            >
                <img src={GreyArrow} />
            </div>
        </>
    );
}

const Testimonials = () => {


    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2.3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 833,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 621,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 421,
                settings: {
                    slidesToShow: 0.5,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
        ],
    };


    return (
        <div className="pt-[62px] pb-[122px] md:pl-[80px] relative w-full">
            <div className="md:flex">
                <div className="text-center w-[100%] px-[14px]">
                    <h2 className="md:text-[36px] text-[34px] leading-[52px] font-semibold text-[#26262A]">What Our Clients Say</h2>
                    <p className="text-[18px] leading-[24px] text-[#3F4045] mt-[13px] mb-[75px]">Here are what some of our clients have to say about us</p>
                </div>
            </div>

            <Slider {...settings}>
                {
                    TESTIMONIALS.map(({ id, image, name, proffession, description }, index) => {
                        return (
                            <div key={index || id} className="flex flex-shrink-0 gap-4 pl-2">
                                <div className={`${styles['custom-slide']}`}>
                                    <img src={image} alt="profile-pic" className="h-full" />

                                    <div>
                                        <h3 className="text-[24px] leading-[38px] text-[#26262A] font-semibold">{name}</h3>
                                        <p className="text-[16px] leading-[24px] text-[#5E6068]">{proffession}</p>
                                        <p className="text-[16px] leading-[24px] text-[#5E6068] mt-[22px]">{description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default Testimonials;