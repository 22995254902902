import React from "react";
import { Accredited, FacebookLogo, FooterLogo, InstaLogo, WhatsappLogo, XLogo, YoutubeLogo } from "../../../images";

const Footer = () => {
    return (
        <footer className="w-full min-[1100px]:min-h-[478px] h-fit bg-[#275A53] mb-0 py-[50px] w-full">
            <div className="flex min-[1100px]:flex-row flex-col items-start justify-between min-[1100px]:px-[50px] px-4 pb-4 min-[1100px]:h-fit">
                <div className="w-fit text-left h-full">
                    <div className="min-[1100px]:w-[246px] text-[#ffffff] flex flex-col gap-2 min-[1100px]:items-center items-start">
                        <img src={FooterLogo} alt="logo" />
                        <p className="text-[18px] leading-[24px] tracking-[0.5%]">We provide a comprehensive range of analytical testing and consulting services.</p>
                    </div>
                </div>

                <div className="h-full w-fit flex items-center min-[1100px]:mt-1 mt-14">
                    <div className="flex flex-col gap-8 min-[1100px]:justify-center min-[1100px]:items-center min-[1100px]:w-[450px] w-[90%]">
                        <ul className="flex min-[1100px]:flex-row flex-col min-[1100px]:items-center min-[1100px]:justify-center gap-6 text-[#ffffff] text-4 leading-6 font-semibold">
                            <li><a href="/">Home</a></li>
                            <li><a href="/about-us">About Us</a></li>
                            <li><a href="/our-services">Our Services</a></li>
                            <li><a href="/contact-us">Contact Us</a></li>
                        </ul>

                        <div className="flex gap-10">
                            <div>
                                <a href="#">
                                    <img src={FacebookLogo} alt="facebook" />
                                </a>
                            </div>

                            <div>
                                <a href="#">
                                    <img src={XLogo} alt="x" />
                                </a>
                            </div>

                            <div>
                                <a href="#">
                                    <img src={InstaLogo} alt="instagram" />
                                </a>
                            </div>

                            <div>
                                <a href="#">
                                    <img src={YoutubeLogo} alt="Youtube" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-fit flex gap-4 justify-end min-[1100px]:items-center min-[1100px]:mt-0 mt-10">
                    <div className="flex flex-col gap-4 min-[1100px]:items-center min-[1100px]:w-[311px]">
                        <h4 className="min-[1100px]:text-[22px] min-[1100px]:leading-[28px] text-[#ffffff] font-semibold">Subscribe to Our Newsletter</h4>

                        <p className="text-[14px] leading-[20px] text-[#ffffff] min-[1100px]:text-center">Receive weekly highlights and curated content directly to your email.</p>

                        <form action="" className="w-full relative">
                            <input type="text" placeholder="Type in your email" className="h-[48px] w-full px-6 border-none focus:outline-none .placeholder-[#C7CAD8]" />
                            <button type="submit" className="h-[34px] w-[30%] bg-[#275A53] text-[#ffffff] font-semibold text-normal absolute right-2 top-2">Submit</button>
                        </form>

                        <div className="w-full flex min-[1100px]:justify-end">
                            <img src={Accredited} alt="accredited" />
                        </div>
                    </div>

                    {/* <img src={WhatsappLogo} alt="whatsapp" className="h-[59px] w-[59px] -mt-24 ml-6 min-[1100px]:ml-0 max-[372px]:w-[20px]" /> */}
                </div>

            </div>

            <div className="border-t border-1 border-[#ffffff] flex flex-col justify-center items-center">
                <p className="text-[12px] leading-[16px] text-[#ffffff] min-[1100px]:w-[70%] mx-auto text-center py-[34px] tracking-[0.4%] px-4">
                    At Myrtle Labs, we stand behind the accuracy and reliability of our laboratory services.
                    We ensure that every analysis is conducted with the highest standards of quality and precision.
                    We are so confident in the quality of our laboratory services that we offer a money-back guarantee.
                    If you are not completely satisfied with the results, we will refund your payment in full.
                </p>

                <p className="text-[#ffffff] text-[12px] leading-[16px]">&copy; 2024 Myrtle Laboratories. All rights reserved</p>
            </div>
        </footer>
    )
}

export default Footer;