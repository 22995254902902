import React from "react";
import PageLayout from "../components/layout";
import AboutUs from "../components/about";

const AboutPage = () => {
    return (
        <PageLayout>
            <AboutUs />
        </PageLayout>
    )
}

export default AboutPage;