import React from "react";
import { AirQuality, ArrowRightGreen, Ensuring } from "../../images";

const Blog = () => {
    return (
        <div className="py-[62px] px-4">
            <div className="text-center">
                <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A]">Our Blog</h2>
                <p className="md:text-[18px] text-[17px] leading-[24px] text-[#5E6068] mt-[13px]">Here are some helpful articles and resources</p>
            </div>

            <div className="mt-[53px] flex justify-center">
                <div className="md:w-[80%] flex md:flex-row flex-col md:gap-[81px] gap-[50px]">
                    <div>
                        <img src={Ensuring} alt="ensuring-compliance" className="w-full"/>
                        <h3 className="text-[24px] leading-[38px] font-semibold text-[#26262A] mt-[14px]">Ensuring Compliance: The Role of Industrial Wastewater Analysis</h3>
                        <p className="text-[16px] leading-[24px] text-[#5E6068] mt-[19px]">Industrial facilities must comply with stringent regulations to manage wastewater discharge effectively.
                            This article will explore the role of wastewater analysis in maintaining compliance, the challenges industries face,
                            and how Pace Analytical Services supports industries in managing their wastewater responsibly.
                        </p>
                        <button className="w-[143px] h-[48px] text-[#275A53] flex gap-2 mt-[18px]">
                            View More
                            <img src={ArrowRightGreen} alt="arrow-right" className="w-[24px] h-[24px]"/>
                        </button>
                    </div>

                    <div>
                        <img src={AirQuality} alt="air-quality" className="w-full"/>
                        <h3 className="text-[24px] leading-[38px] font-semibold text-[#26262A] mt-[14px]">Air Quality Assessment: Safeguarding Our Indoor and Outdoor Environments</h3>
                        <p className="text-[16px] leading-[24px] text-[#5E6068] mt-[19px]">Air quality has a direct impact on human health and the environment.
                            This article will discuss the necessity of air quality assessments, the common pollutants detected,
                            and how laboratories like Myrtle Labs contribute to creating healthier indoor and outdoor environments through precise air quality testing.
                        </p>
                        <button className="w-[143px] h-[48px] text-[#275A53] flex gap-2 mt-[18px]">
                            View More
                            <img src={ArrowRightGreen} alt="arrow-right" className="w-[24px] h-[24px]"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog;