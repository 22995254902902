import React from "react";
import { ContactJumbo, EmailIcon, LocationPin, PhoneIcon } from "../../images";
import { useForm } from 'react-hook-form'


const ContactUs = () => {

    const backgroundImageStyle = {
        backgroundImage: `url(${ContactJumbo})`
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    }
    return (
        <div>
            <div style={backgroundImageStyle} className="h-[280px] bg-center bg-cover">
                <div className="bg-[#000000] bg-opacity-[45%] py-[94px] min-[900px]:px-[96px] px-4 h-full">
                    <h1 className="text-[48px] leading-[64px] font-bold text-[#FFFFFF] text-center">Contact Us</h1>
                </div>
            </div>

            <div className="min-[900px]:py-[93px] py-[88px] min-[900px]:px-[80px] flex min-[900px]:flex-row flex-col min-[900px]:gap-[141px] gap-[88px]">
                <div className="py-[84px] min-[900px]:pl-[31px] min-[900px]:pr-[63px] px-4 bg-[#4B7670] min-[900px]:w-[570px] w-full">
                    <h2 className="min-[900px]:text-[36px] text-[34px] leading-[52px] font-semibold text-[#FFFFFF] ">Get In Touch With Us</h2>
                    <p className="text-[16px] leading-[24px] text-[#FFFFFF] mt-[24px]">You are what matter most to us. We provide a tailored service delivery that matches your requirements. Over the phone, at our front desks, or by email,
                        this means a trained and responsive Customer Service team focused on delivering quality and customized services to pair our laboratory competency with your specific needs.
                    </p>

                    <div className="mt-[64px] flex flex-col gap-[48.5px] text-[#FFFFFF] text-[18px] leading-[24px]">
                        <div className="flex gap-4">
                            <img src={PhoneIcon} alt="phone" className="w-[24px] h-[24px]" />
                            <p>+2348000000</p>
                        </div>
                        <div className="flex gap-4">
                            <img src={EmailIcon} alt="email" className="w-[24px] h-[24px]" />
                            <p>myrtlelab.info@gmail.com</p>
                        </div>
                        <div className="flex gap-4">
                            <img src={LocationPin} alt="email" className="w-[24px] h-[24px]" />
                            <p>Suite 3, Blake Plaza, Lagos, Nigeria</p>
                        </div>
                    </div>
                </div>

                <div className="min-[900px]:w-[456px] w-full px-4">
                    <form action="" className="flex flex-col gap-6 w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <label htmlFor="fullname" className="text-[16px] leading-[24px] font-semibold text-[#26262A] mb-2 block">Full Name <span className="text-[#D34222]">*</span></label>
                            <input
                                type="text"
                                {...register("fullname", { required: true })}
                                id="fullname"
                                placeholder="Type in your full name"
                                className="h-[61px] w-full border-[1px] border-[#C7CAD8] px-[12px] .placeholder-color-[#C7CAD8] focus:outline-none"
                            />
                            {errors.fullname && <span className="text-[10px] text-[#D34222]">This field is required</span>}
                        </div>

                        <div>
                            <label htmlFor="email" className="text-[16px] leading-[24px] font-semibold text-[#26262A] mb-2 block">Email <span className="text-[#D34222]">*</span></label>
                            <input
                                type="email"
                                {...register("email", { required: true })}
                                id="email"
                                placeholder="Type in your email"
                                className="h-[61px] w-full border-[1px] border-[#C7CAD8] px-[12px] .placeholder-color-[#C7CAD8] focus:outline-none"
                            />
                            {errors.email && <span className="text-[10px] text-[#D34222]">This field is required</span>}
                        </div>

                        <div>
                            <label htmlFor="phone" className="text-[16px] leading-[24px] font-semibold text-[#26262A] mb-2 block">Phone <span className="text-[#D34222]">*</span></label>
                            <input
                                type="phone"
                                {...register("phone", { required: true })}
                                id="phone"
                                placeholder="Type in your phone number"
                                className="h-[61px] w-full border-[1px] border-[#C7CAD8] px-[12px] .placeholder-color-[#C7CAD8] focus:outline-none"
                            />
                            {errors.phone && <span className="text-[10px] text-[#D34222]">This field is required</span>}
                        </div>

                        <div>
                            <label htmlFor="help" className="text-[16px] leading-[24px] font-semibold text-[#26262A] mb-2 block focus:outline-none">Message <span className="text-[#D34222]">*</span></label>
                            <textarea 
                            name="help" 
                            id="help" 
                            {...register("help", { required: true })} 
                            placeholder="How can we help you?" 
                            className="border-[1px] border-[#C7CAD8] w-full h-[145px] p-[12px]"></textarea>
                            {errors.help && <span className="text-[10px] text-[#D34222]">This field is required</span>}
                        </div>

                        <div className="flex justify-center">
                            <button type="submit" className="w-[142px] h-[48px] bg-[#275A53] text-[#FFFFFF] ">Submit</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
};


export default ContactUs;