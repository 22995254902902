import React from "react";
import { ArrowRight } from "../../../images";
import ScheduleBtn from "../../shared/appointment-btn";

const MobileSidebar = () => {
    return (
        <div className="flex flex-col items-start py-10 px-4 gap-14 min-h-[100vh] bg-[#ffffff]">
            <ul className="flex flex-col gap-14 text-[#26262A] text-4 leading-6 font-semibold ">
                <li><a href="/">Home</a></li>
                <li><a href="/about-us">About Us</a></li>
                <li><a href="/our-services">Our Services</a></li>
                <li><a href="/contact-us">Contact Us</a></li>
            </ul>

            <ScheduleBtn />
        </div>
    )
}

export default MobileSidebar;