import React from "react";
import { useForm } from 'react-hook-form';
import { CloseIcon, NamelessLogo } from "../../images";

const Appointment = ({ show, handleClose }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };

    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 overflow-y-auto flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="sm:w-[520px] w-full bg-white pb-[37px] rounded-md shadow-lg sm:pt-[35vh] pt-[25vh]">
                <div className="sm:flex justify-between px-5 sm:py-2 my-4">
                    <img src={NamelessLogo} alt="logo" />
                    <button onClick={handleClose} className="max-[640px]:absolute max-[640px]:top-[10px] max-[640px]:right-[10px]">
                        <img src={CloseIcon} alt="close icon" />
                    </button>
                </div>

                <h4 className="text-[22px] leading-[28px] font-semibold text-[#26262A] text-center">Schedule Appointment</h4>

                <form className="flex flex-col gap-6 w-full px-[32px] mt-[32px]" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="fullname" className="text-[16px] leading-[24px] font-semibold text-[#26262A] mb-2 block">
                            Full Name <span className="text-[#D34222]">*</span>
                        </label>
                        <input
                            type="text"
                            {...register("fullname", { required: "Full name is required" })}
                            id="fullname"
                            placeholder="Type in your full name"
                            className="h-[61px] w-full border-[1px] border-[#C7CAD8] px-[12px] placeholder-[#C7CAD8] focus:outline-none"
                            aria-label="Full Name"
                        />
                        {errors.fullname && <span className="text-[10px] text-[#D34222]">{errors.fullname.message}</span>}
                    </div>

                    <div>
                        <label htmlFor="email" className="text-[16px] leading-[24px] font-semibold text-[#26262A] mb-2 block">
                            Email <span className="text-[#D34222]">*</span>
                        </label>
                        <input
                            type="email"
                            {...register("email", { required: "Email is required" })}
                            id="email"
                            placeholder="Type in your email"
                            className="h-[61px] w-full border-[1px] border-[#C7CAD8] px-[12px] placeholder-[#C7CAD8] focus:outline-none"
                            aria-label="Email"
                        />
                        {errors.email && <span className="text-[10px] text-[#D34222]">{errors.email.message}</span>}
                    </div>

                    <div>
                        <label htmlFor="phone" className="text-[16px] leading-[24px] font-semibold text-[#26262A] mb-2 block">
                            Phone <span className="text-[#D34222]">*</span>
                        </label>
                        <input
                            type="tel"
                            {...register("phone", { required: "Phone number is required" })}
                            id="phone"
                            placeholder="Type in your phone number"
                            className="h-[61px] w-full border-[1px] border-[#C7CAD8] px-[12px] placeholder-[#C7CAD8] focus:outline-none"
                            aria-label="Phone"
                        />
                        {errors.phone && <span className="text-[10px] text-[#D34222]">{errors.phone.message}</span>}
                    </div>

                    <div>
                        <label htmlFor="help" className="text-[16px] leading-[24px] font-semibold text-[#26262A] mb-2 block">
                            What service are you interested in? <span className="text-[#D34222]">*</span>
                        </label>
                        <textarea
                            name="help"
                            id="help"
                            {...register("help", { required: "Please describe how we can help you" })}
                            placeholder="How can we help you?"
                            className="border-[1px] border-[#C7CAD8] w-full h-[145px] p-[12px] placeholder-[#C7CAD8] focus:outline-none"
                            aria-label="Service Interest"
                        ></textarea>
                        {errors.help && <span className="text-[10px] text-[#D34222]">{errors.help.message}</span>}
                    </div>

                    <div className="flex justify-center">
                        <button type="submit" className="w-[142px] h-[48px] bg-[#275A53] text-[#FFFFFF]">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Appointment;
