import React, { useState } from "react";
import { ArrowRightGreen, CloseAccordion, OpenAccordion, Scientist2 } from "../../images";
import { useNavigate } from 'react-router-dom';

const Services = () => {
    const navigate = useNavigate();
    const [openAccordion, setOpenAccordion] = useState(null);

    return (
        <div className="bg-[#4B7670] text-[#FFFFFF] flex md:flex-row flex-col md:items-start md:justify-center md:gap-[94px] gap-[60px] md:py-[91px] py-[86px] md:px-[74px] px-4">
            <div className="md:w-[648px] md:h-[508px] w-full">
                <img src={Scientist2} alt="scientist" className="w-full h-full" />
            </div>

            <div className="md:w-[612px] w-full">
                <h2 className="font-semibold text-[36px] leading-[52px]">Services We Offer</h2>
                <p className="text-[18px] leading-[24px] mt-8">We provide a comprehensive range of analytical testing and consulting services to meet your environmental and quality assurance needs.
                    We are sure to deliver accurate results and expert insights to suit you specific requirements
                </p>

                <div className="flex py-4 gap-10">
                    <div className="mt-7 flex flex-col gap-[22px] py-4">
                        <div>
                            <div className="flex items-center gap-4">
                                <img src={OpenAccordion} alt="accordion" className="w-6 h-6 rounded-full" />
                                <h3 className="text-[16px] leading-[38px]">Ground Water Analysis</h3>
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center gap-4">
                                <img src={OpenAccordion} alt="accordion" className="w-6 h-6 rounded-full" />
                                <h3 className="text-[16px] leading-[38px]">Waste Water Analysis</h3>
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center gap-4">
                                <img src={OpenAccordion} alt="accordion" className="w-6 h-6 rounded-full" />
                                <h3 className="text-[16px] leading-[38px]">Food Analysis</h3>
                            </div>
                        </div>
                    </div>

                    <div className="mt-7 flex flex-col gap-[22px] py-4">
                        <div>
                            <div className="flex items-center gap-4">
                                <img src={OpenAccordion} alt="accordion" className="w-6 h-6 rounded-full" />
                                <h3 className="text-[16px] leading-[38px]">Surface water Analysis</h3>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src={OpenAccordion} alt="accordion" className="w-6 h-6 rounded-full" />
                                <h3 className="text-[16px] leading-[38px]">Soil Analysis</h3>
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center gap-4">
                                <img src={OpenAccordion} alt="accordion" className="w-6 h-6 rounded-full" />
                                <h3 className="text-[16px] leading-[38px]">Air Quality Monitoring</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <button className="w-[211px] h-[48px] text-[#275A53] text-[16px] leading-[24px] font-semibold bg-[#FFFFFF] mt-7 flex items-center justify-center gap-1" onClick={() => navigate("/our-services")}>
                    View More
                    <img src={ArrowRightGreen} alt="right-arrow" />
                </button>
            </div>
        </div>
    )
}

export default Services;