import React from "react";
import { ArrowRight, ServicesCTA, service1 } from "../../images";
import { SERVICES } from "../../data/services";
import { OTHER_SERVICES } from "../../data/other-services";
import CallToAction from "../home/cta";
import ScheduleBtn from "../shared/appointment-btn";


const Services = () => {
    return (
        <div>
            <div className="sm:h-[438px] flex items-center justify-center px-4 py-[87px]">
                <div className="sm:w-[50%] text-center flex flex-col gap-10 items-center">
                    <h1 className="text-[48px] leading-[64px] font-bold text-[#26262A]">Our Services</h1>
                    <p className="text-[18px] leading-[24px] text-[#5E6068] mt-[40px]">Our Services encompass a comprehensive range of analytical testing and consulting solutions to meet your environmental and quality assurance needs.
                        From water and soil analysis to air quality and waste management, we provide precise results and expert insights tailored to your specific requirements
                    </p>

                    <div className="mb-[40px]">
                        <ScheduleBtn />
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center">
                <div className="sm:px-[80px] px-4 flex items-stretch gap-[20px] flex-wrap w-full">
                    {
                        SERVICES.map(({ id, image, title, description }, index) => {
                            return (
                                <div key={index || id} className="max-[1150px]:w-[45%] w-[32%] max-[700px]:w-full sm:p-[24px] py-[24px] px-[16px] shadow-custom sm:mb-[48px]">
                                    <img src={image} alt={title} className="h-[189px] w-full" />
                                    <h3 className="text-[24px] leading-[38px] font-sembold mt-[20px] text-[#26262A]">{title}</h3>
                                    <p className="text-[16px] leading-[24px] text-[#5E6068] mt-[16px]">{description}</p>
                                </div>
                            )
                        })
                    }
                </div>

                <ScheduleBtn />
            </div>

            <div className="sm:h-[365px] flex items-center justify-center px-4 py-[87px]">
                <div className="sm:w-[50%] text-center flex flex-col items-center">
                    <h1 className="text-[48px] leading-[64px] font-bold text-[#26262A]">Other Services</h1>
                    <p className="text-[18px] leading-[24px] text-[#5E6068] mt-[40px]">We have additional services tailored to meet diverse environmental testing needs.
                        With our wide range of capabilities and expertise, we ensure comprehensive solutions for all your laboratory and field requirements.
                    </p>
                </div>
            </div>

            <div className="sm:px-[80px] px-4 flex items-stretch gap-[20px] flex-wrap w-full mb-[100px]">
                {
                    OTHER_SERVICES.map(({ id, image, title, description }, index) => {
                        return (
                            <div key={index || id} className="max-[1150px]:w-[45%] w-[32%] max-[700px]:w-full sm:p-[24px] py-[24px] px-[16px] shadow-custom sm:mb-[48px]">
                                <img src={image} alt={title} className="h-[189px]" />
                                <h3 className="text-[24px] leading-[38px] font-sembold mt-[20px] text-[#26262A]">{title}</h3>
                                <p className="text-[16px] leading-[24px] text-[#5E6068] mt-[16px]">{description}</p>
                            </div>
                        )
                    })
                }
            </div>

            <CallToAction CTAImage={ServicesCTA} message={"Talk With Us Today"} />
        </div>
    )
}

export default Services;