import React from "react";
import PageLayout from "../components/layout";
import ContactUs from "../components/contact";

const ContactPage = () => {
    return (
        <PageLayout>
            <ContactUs />
        </PageLayout>
    )
}

export default ContactPage;