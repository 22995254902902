import React from "react";
import { CASE_STUDIES } from "../../data/case-studies";
import { useNavigate } from 'react-router-dom';

const CaseStudies = () => {

    const navigate = useNavigate();

    return (
        <div className="bg-[#F2F2F6] py-[61px] px-4 sm:flex sm:justify-center">
            <div className="sm:w-[70%]">
                <div >
                    <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A]">Case Studies</h2>
                    <p className="text-[18px] leading-[24px] text-[#5E6068] mt-[13px]">Not to brag, but we've had the pleasure of working on some advanced projects</p>
                </div>

                <div className="mt-[56px] flex md:flex-row flex-col gap-[21px]">
                    {
                        CASE_STUDIES.map(({id, title, description, link}, index) => (
                            <div className="bg-[#ffffff] p-8 sm:w-1/2" key={id || index}>
                                <h3 className="text-[24px] leading-[38px] font-semibold text-[#26262A]">{title}</h3>
                                <p className="text-[16px] leading-[24px] text-[#5E6068] mt-[19px]">{description}</p>

                                <button className="w-[159px] h-[48px] border-[1px] border-[#275A53] text-[#275A53] mt-[19px] focus:otline-none" onClick={() => navigate(link)}>Read More</button>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default CaseStudies;