import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AboutPage, ContactPage, HomePage, ServicesPage } from './pages';

const MyRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/about-us" element={<AboutPage />} />
                <Route exact path="/our-services" element={<ServicesPage />} />
                <Route exact path="/contact-us" element={<ContactPage />} />

            </Routes>
        </BrowserRouter>
    )
}

export default MyRoutes;