import React, { useState } from "react";
import TopNav from "./top-nav";
import { ArrowRight, CloseIcon, Menu, NavLogo } from "../../../images";
import MobileSidebar from "./mobile-sidebar";
import ScheduleBtn from "../../shared/appointment-btn";

const Nav = () => {

    const [openSidebar, setOpenSidebar] = useState(false);

    return (
        <div>
            <TopNav />

            <nav className="w-full min-[1100px]:h-[100px] h-[88px] flex justify-between items-center min-[1100px]:px-[90px] py-[24px] px-[16px] shadow-custom">
                <img src={NavLogo} alt="logo" className="min-[1100px]:w-[184px] w-[80px] min-[1100px]:h-[87px] h-[34px] h-[40px]" />

                <div className="flex justify-end items-center gap-16">
                    <ul className="min-[1100px]:flex hidden items-center gap-12 text-[#26262A] text-4 leading-6 font-semibold ">
                        <li><a href="/">Home</a></li>
                        <li><a href="/about-us">About Us</a></li>
                        <li><a href="/our-services">Our Services</a></li>
                        <li><a href="/contact-us">Contact Us</a></li>
                    </ul>

                    <span className="w-[3px] h-[36px] rounded-[99px] bg-[#D9D9D9] min-[1100px]:block hidden"></span>

                    <div className="hidden min-[1100px]:block">
                        <ScheduleBtn />
                    </div>

                </div>

                {!openSidebar ? 
                <img src={Menu} alt="menu" className="min-[1100px]:hidden block cursor-pointer" onClick={() => setOpenSidebar(!openSidebar)}/>
                :
                <img src={CloseIcon} alt="close" className="min-[1100px]:hidden block cursor-pointer" onClick={() => setOpenSidebar(!openSidebar)}/>
            }
            </nav>

            {openSidebar && <MobileSidebar />}
        </div>

    )
}

export default Nav;