import React from "react";
import { AboutUsBackground } from "../../images";

const AboutJumbo = () => {
    const backgroundImageStyle = {
        backgroundImage: `url(${AboutUsBackground})`,
    };

    return (
        <div style={backgroundImageStyle} className="sm:h-[485px] h-[753px] bg-center bg-cover">
            <div className="bg-[#000000] bg-opacity-[45%] py-[94px] sm:px-[96px] px-4 h-full">
                <h1 className="text-[48px] leading-[64px] font-bold text-[#FFFFFF]">About Myrtle Lab </h1>
                <p className="sm:w-[40%] text-[18px] leading-[24px] text-[#FFFFFF] pt-[34px]">At Myrtle Lab, we are dedicated to providing accurate and reliable environmental testing services.
                    Our team of seasoned experts uses state-of-the-art technology to deliver precise analytical results and trusted insights.
                    Committed to sustainability and quality, we help our clients meet their environmental and regulatory needs with confidence.
                </p>
            </div>

        </div>
    )
}

export default AboutJumbo;