import React from "react";
import { BENEFITS } from "../../data/benefits";
import ScheduleBtn from "../shared/appointment-btn";

const Benefits = () => {
    return (
        <div className="bg-[#F2F2F6] flex min-[1125px]:flex-row flex-col min-[1125px]:gap-[44px] min-[1125px]:justify-center min-[1125px]:items-center min-[1125px]:py-[155px] py-[80px] min-[1125px]:px-[82px] px-4">
            <div className="min-[1125px]:w-[407px] w-full">
                <h2 className="text-[32px] leading-[56px] font-semibold text-[#26262A]">Key Benefits of Our Services at MYRTLE</h2>
                
                <div className="mt-[30px] mb-[56px]">
                <ScheduleBtn />
                </div>
            </div>

            <div className="min-[1125px]:w-[825px] flex min-[1125px]:flex-row flex-col min-[1125px]:gap-7 gap-[56px]">
                {
                    BENEFITS.map(({id, image, title, description}, index) => (
                        <div className="w-full min-[1125px]:w-[256px]" key={id || index}>
                            <img src={image} alt="staff" className="w-full min-[1125px]:h-[180px]" />
                            <h3 className="text-[24px] leading-[38px] font-sembold mt-[20px] text-[#26262A]">{title}</h3>
                            <p className="text-[18px] leading-[24px] text-[#5E6068] mt-4">{description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Benefits;