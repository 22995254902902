import { service1, service10, service2, service3, service4, service5, service6, service7, service8, service9 } from "../images";

export const SERVICES = [
    {
        id: 1,
        image: service1,
        title: "Ground Water Analysis",
        description: "We provide precise testing and detailed reports on groundwater quality and contamination. Using advanced techniques, we identify pollutants and assess water safety, helping you ensure compliance with environmental standards and protect public health."
    },
    {
        id: 2,
        image: service2,
        title: "Surface Water Analysis",
        description: "We offer comprehensive testing to evaluate the quality of lakes, rivers, and streams. We identify contaminants and assess ecological health, providing you with reliable data to support environmental compliance and water management efforts."
    },
    {
        id: 3,
        image: service3,
        title: "Waste Water Analysis",
        description: "This service delivers thorough testing to identify pollutants and assess the quality of industrial and municipal wastewater. We provide detailed and expert insights, ensuring your wastewater management practices meet regulatory standards and protect the environment."
    },
    {
        id: 4,
        image: service4,
        title: "Soil Analysis",
        description: "Our Soil Analysis service offers precise testing to determine soil composition and contamination levels. We provide detailed insights and recommendations, helping you make informed decisions for agriculture, construction, and environmental remediation."
    },
    {
        id: 5,
        image: service5,
        title: "Sediment Analysis",
        description: "Our Sediment Analysis service provides comprehensive testing to assess the composition and contamination levels of sediment samples. We deliver accurate results and expert recommendations, supporting environmental assessments and remediation projects."
    },
    {
        id: 6,
        image: service6,
        title: "Food Analysis",
        description: "Our Food Analysis service offers detailed testing to ensure the safety and quality of food products. We identify contaminants, verify nutritional content, and ensure compliance with regulatory standards, providing you with reliable data to protect consumer health."
    },
    {
        id: 7,
        image: service7,
        title: "Air Quality Monitoring",
        description: "We provide Air Quality Monitoring service that delivers precise testing to evaluate indoor and outdoor air quality. We identify pollutants and provide actionable insights, helping you maintain a healthy environment and comply with regulatory standards."
    },
    {
        id: 8,
        image: service8,
        title: "Noise Monitoring",
        description: "This service provides accurate assessments of noise levels in various environments. We identify sources of excessive noise and offer actionable data to help you meet regulatory standards and create quieter, more comfortable spaces."
    },
    {
        id: 9,
        image: service9,
        title: "Field Sampling",
        description: "Our Field Sampling service ensures the accurate collection of environmental samples from various sites. We adhere to rigorous protocols and use advanced techniques to gather reliable data, providing you with a solid foundation for your analysis and decision-making."
    },
    {
        id: 10,
        image: service10,
        title: "Data Acquisition",
        description: "Our Data Acquisition service employs advanced technologies to gather environmental data with precision and efficiency. We offer real-time monitoring and comprehensive data analysis, empowering you to make informed decisions and meet regulatory requirements effectively."
    },
]