import logo1 from "../../../images/logo1.png";
import logo2 from "../../../images/logo2.png";
import logo3 from "../../../images/logo3.png";
import logo4 from "../../../images/logo4.png";

export default function index() {
  return (
    <div className="px-5 md:px-14 py-10 flex flex-col gap-10 justify-center items-center text-center">
      <div className="flex flex-col gap-4 items-center">
        <h3 className="text-2xl md:text-[36px] font-semibold">Accreditation</h3>
        <p className="md:w-2/3">
          Our business proudly holds full accreditation from these leading
          institutions, ensuring the highest standards of quality and
          reliability in all our services.
        </p>
      </div>
      <div className="flex justify-evenly flex-col md:flex-row gap-5 md:gap-10">
        <Single text="Federal Ministry of environment" img={logo1} />
        <Single
          text="Nigerian Upstream Petroleum Regulatory Commission"
          img={logo2}
        />
        <Single
          text="Nigerian MidStream And Upstream Petroleum Regulatory Authority"
          img={logo3}
        />
        <Single
          text="National Environmental Standards and Regulations Enforcement Agency"
          img={logo4}
        />
      </div>
    </div>
  );
}

const Single = ({ text, img }) => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center">
      <img src={img} alt="" className="object-contain" />
      <p className="md:max-w-[210px]">{text}</p>
    </div>
  );
};
