import React from "react";
import { ArrowRight, Chemistry, Goal, Scientist, TestTube } from "../../images";
import CountUp from 'react-countup';
import ScheduleBtn from "../shared/appointment-btn";

const Jumbo = () => {
    return (
        <div>
            <div className="flex md:flex-row flex-col md:items-center md:justify-between gap-[68px]">
                <div className="md:w-1/2 flex flex-col gap-10 md:pl-[80px] md:pr-0 px-4 md:w-[514px] w-full">
                    <h1 className="text-[#26262A] text-[48px] leading-[64px] font-bold w-full">Precision Analysis for Every Element of the Environment</h1>
                    <p className="text-[18px] leading-[24px] text-[#3F4045]">You can rely on our advanced expertise for accurate and comprehensive testing
                        of all environmental properties including water, soil, and food, ensuring the highest standards of environmental quality and safety.
                    </p>
                    <ScheduleBtn />
                </div>

                <div className="md:w-1/2 w-full">
                    <img src={Scientist} alt="scientist" className="md:w-[721px] md:h-[566px] w-full" />
                </div>
            </div>

            <div className="mt-[50px]">
                <h2 className="font-semibold text-[36px] leading-[52px] text-[#26262A] text-center">Making a Difference Every Single Day</h2>
                <div className="flex md:flex-row flex-col gap-[34px] md:justify-center items-center mt-[41px] mb-[50px]">
                    <div className="w-[312px] h-[262px] rounded-[8px] px-5 py-8 flex flex-col gap-4 items-start shadow-custom">
                        <img src={Chemistry} alt="chemistry" />
                        <h2 className="text-[36px] leading-[52px] text-[#26262A] font-semi-bold"><CountUp separator="," duration={3} enableScrollSpy={true}  end={2000} /> +</h2>
                        <p>Over 2,000 Lab Test carried out each year for our clients.</p>
                    </div>

                    <div className="w-[312px] h-[262px] rounded-[8px] px-5 py-8 flex flex-col gap-4 items-start shadow-custom">
                        <img src={Goal} alt="Goal" />
                        <h2 className="text-[36px] leading-[52px] text-[#26262A] font-semi-bold"><CountUp separator="," duration={3} enableScrollSpy={true}  end={100} /> +</h2>
                        <p>Our analysis are accurate leaving a low chance to errors.</p>
                    </div>

                    <div className="w-[312px] h-[262px] rounded-[8px] px-5 py-8 flex flex-col gap-4 items-start shadow-custom">
                        <img src={TestTube} alt="TestTube" />
                        <h2 className="text-[36px] leading-[52px] text-[#26262A] font-semi-bold"><CountUp separator="," duration={3} enableScrollSpy={true}  end={500} /> +</h2>
                        <p>We have a wide range of services to meet your analytical needs.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Jumbo;