import React from "react";
import { Integrity, Learning, Mission, Proffessionalism, Vision } from "../../images";

const CoreValues = () => {
    return (
        <div>
            <div className="min-[770px]:pt-[87px] min-[770px]:pb-[40px] pt-[80px] pb-[40px] min-[770px]:px-[80px] px-4">
                <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A] text-center">Our Core Values</h2>

                <div className="mt-[80px]">
                    <div className="flex min-[770px]:flex-row flex-col items-center justify-between">
                        <img src={Integrity} alt="integrity" className="min-[770px]:w-1/2 h-[297px] " />

                        <div className="min-[770px]:w-1/2 min-[770px]:pl-[145px] min-[770px]:mt-0 mt-[56px]">
                            <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A]">Integrity</h2>
                            <p className="text-[18px] leading-[24px] text-[#26262A] mt-[32px]">We deliver our promises in essence of the way we do business.
                                Our commitment to integrity, accuracy, and customer satisfaction is reflected in every service we provide.
                            </p>
                        </div>
                    </div>

                    <div className="flex min-[770px]:flex-row flex-col items-center justify-between mt-[64px] sm min-[770px]:mb-0 mb-[64px]">
                        <div className="min-[770px]:w-1/2 min-[770px]:pr-[145px] min-[770px]:order-first order-last min-[770px]:mt-0 mt-[56px]">
                            <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A]">Professionalism</h2>
                            <p className="text-[18px] leading-[24px] text-[#26262A] mt-[32px]">We strive to provide every client with quality products and services We are progressive and innovative by constantly seeking ways to enhance patient care.
                            </p>
                        </div>

                        <img src={Proffessionalism} alt="Proffessionalism" className="min-[770px]:w-1/2 h-[297px]" />
                    </div>

                    <div className="flex min-[770px]:flex-row flex-col items-center justify-between mt-[64px]">
                        <img src={Learning} alt="Learning" className="min-[770px]:w-1/2 h-[297px]" />

                        <div className="min-[770px]:w-1/2 min-[770px]:pl-[145px] min-[770px]:mt-0 mt-[56px]">
                            <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A]">Continuous Learning</h2>
                            <p className="text-[18px] leading-[24px] text-[#26262A] mt-[32px]">We improve our skills and abilities through learning and self-development.
                                By implementing new technologies and approaches, we adapt ourselves to dynamic market growth and challenges. Learning is our habit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center min-[770px]:py-[108px] py-[80px] px-4">
                <div className="min-[770px]:w-[70%]">
                    <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A] text-center">Who We Are</h2>

                    <div className="flex items-stretch min-[770px]:flex-row flex-col gap-[34px] mt-[64px]">
                        <div className="min-[770px]:w-1/2 flex flex-col items-start gap-4 min-[770px]:p-5 px-5 py-[11px] shadow-custom mb-4 rounded-[8px]">
                            <img src={Vision} alt="vision" className="w-[60px] h-[60px]"/>
                            <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A]">Our Vision</h2>
                            <p className="text-[18px] leading-[24px] text-[#26262A]">To be the preferred partner and one-stop testing service provider.</p>
                        </div>

                        <div className="min-[770px]:w-1/2 flex flex-col items-start gap-4 min-[770px]:p-5 px-5 py-[11px] shadow-custom mb-4 rounded-[8px]">
                            <img src={Mission} alt="mission" className="w-[60px] h-[60px]"/>
                            <h2 className="text-[36px] leading-[52px] font-semibold text-[#26262A]">Our Mission</h2>
                            <p className="text-[18px] leading-[24px] text-[#26262A]">To be a one-stop testing service provider in Nigeria by offering high quality and accurate testing with fast and reliable turn-around times.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoreValues;