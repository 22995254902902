import { BlackMan, Theresa, Williams } from "../images";

export const TESTIMONIALS = [
    {
        id: 1,
        image: Williams,
        name: "Williams Robinson",
        proffession: "Waste Water Expert",
        description: "Myrtle Lab provides a high level of professionalism with a quick quality and reasonably priced service. Their analytical methods are commendable."
    },
    {
        id: 2,
        image: Theresa,
        name: "Theresa Salami",
        proffession: "Environmental Impact Analyst",
        description: "I am totally impressed with the caliber of test services offered by Myrtle Lab. Each sample undergoes meticulous scrutiny, results are accurate and timely."
   
    },
    {
        id: 3,
        image: BlackMan,
        name: "David Salami",
        proffession: "Soil Profile Analyst",
        description: "I am totally impressed with the caliber of test services offered by Myrtle Lab. Each sample undergoes meticulous scrutiny, results are accurate and timely."
   
    },
    {
        id: 4,
        image: Williams,
        name: "Williams Robinson",
        proffession: "Waste Water Expert",
        description: "Myrtle Lab provides a high level of professionalism with a quick quality and reasonably priced service. Their analytical methods are commendable."
    },
    {
        id: 5,
        image: Theresa,
        name: "Theresa Salami",
        proffession: "Environmental Impact Analyst",
        description: "I am totally impressed with the caliber of test services offered by Myrtle Lab. Each sample undergoes meticulous scrutiny, results are accurate and timely."
   
    },
    {
        id: 6,
        image: BlackMan,
        name: "David Salami",
        proffession: "Soil Profile Analyst",
        description: "I am totally impressed with the caliber of test services offered by Myrtle Lab. Each sample undergoes meticulous scrutiny, results are accurate and timely."
   
    },
    {
        id: 7,
        image: Williams,
        name: "Williams Robinson",
        proffession: "Waste Water Expert",
        description: "Myrtle Lab provides a high level of professionalism with a quick quality and reasonably priced service. Their analytical methods are commendable."
    },
    {
        id: 8,
        image: Theresa,
        name: "Theresa Salami",
        proffession: "Environmental Impact Analyst",
        description: "I am totally impressed with the caliber of test services offered by Myrtle Lab. Each sample undergoes meticulous scrutiny, results are accurate and timely."
   
    },
    {
        id: 9,
        image: BlackMan,
        name: "David Salami",
        proffession: "Soil Profile Analyst",
        description: "I am totally impressed with the caliber of test services offered by Myrtle Lab. Each sample undergoes meticulous scrutiny, results are accurate and timely."
   
    }
]