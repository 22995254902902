import React, { useState } from 'react';
import Appointment from '../modals/schedule-appointment';
import { ArrowRight } from '../../images';

const ScheduleBtn = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <div>
            <button className="w-[224px] h-[48px] bg-[#275A53] text-[#ffffff] flex items-center justify-center gap-2 text-[16px] leading-6" onClick={handleShow}>
                Schedule Appointment
                <img src={ArrowRight} alt="arrow right" />
            </button>
            <Appointment show={showModal} handleClose={handleClose} />
        </div>
    );
};

export default ScheduleBtn;