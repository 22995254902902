export const CASE_STUDIES = [
    {
        id: "cs-1",
        title: "Surface Water Quality Monitoring",
        description: "We partnered with a municipal water authority to monitor surface water quality in a major river. Through regular sampling and advanced analysis, we helped ensure compliance with environmental regulations and safeguard publi..",
        link: "#"
    },
    {
        id: "cs-2",
        title: "Indoor Air Quality Testing in Schools",
        description: "We worked with a school district to assess indoor air quality in several schools. Our testing identified mold and other pollutants, leading to targeted improvements that ensured a healthier learning environment.",
        link: "#"
    },
]