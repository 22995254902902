import React from "react";
import AboutJumbo from "./jumbo";
import CoreValues from "./core-values";
import CallToAction from "../home/cta";
import { AboutCTA } from "../../images";

const AboutUs = () => {
    return (
        <div>
            <AboutJumbo />
            <CoreValues />
            <CallToAction CTAImage={AboutCTA}/>
        </div>
    )
}

export default AboutUs;